.chat-container {
    display: flex;
    background: #181818;
    height: 100vh;
  }
  
  .sidebar {
    width: 25%;
    background: #181818;
    padding: 15px;
    border-right: 1px solid #ddd;
    overflow-y: auto;
  }
  

  .search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .chat-list {
    display: flex;
    flex-direction: column;
  }
  
  .chat-item {
    padding: 10px;
    margin-bottom: 5px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chat-item.active {
    background: #6558f5;
    color: #fff;
  }
  
  /* Club list */
  .club-list {
    display: flex;
    flex-direction: column;
  }
  
  .club-item {
    padding: 10px;
    margin-bottom: 5px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .club-item.active {
    background: #6558f5;
    color: #fff;
  }
  
  /* Main chat area */
  .chat-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .chat-main h2 {
    color: white;
  }
  

  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 10px;
  }
  

  .chat-message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    max-width: 60%;
  }
  

  .sent {
    align-self: flex-end;
    margin-left: auto;
    margin-right: 0;
    background: #6558f5;
    color: #fff;
  }
  

  .received {
    align-self: flex-start;
    margin-left: 0;
    margin-right: auto;
    background: #e0e0e0;
  }
  

  .message-meta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 10px;
  }

  .chat-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
  }
  
  .sender-id {
    margin-right: 6px;
    color: #999; /* Light text color */
  }
  
  .read-icon {
    font-size: 14px;
  }
  
  /* Chat input area */
  .chat-input-container {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  .chat-input {
    flex: 1;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .chat-send-btn {
    padding: 8px 15px;
    margin-left: 10px;
    background: #6558f5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  