.club-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
  box-sizing: border-box;
}

.club-info-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
  margin-bottom: 20px;
}

.club-info-item {
  flex: 1;
  margin: 0 10px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.club-info-item:hover {
  transform: translateY(-5px);
}

.club-info-item h2 {
  font-size: 20px;
  color: #555;
  margin-bottom: 10px;
}

.club-info-item p {
  font-size: 16px;
  color: #333;
}

button {
  margin-top: 10px;
  padding: 8px 20px;
  background-color: #6558f5;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

button:hover {
  background-color: #4a49e0;
}

.fixtures {
  width: 100%;
  max-width: 900px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.fixtures h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.fixture-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.fixture-item p {
  margin: 5px 0;
}

.fixture-club-name {
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.fixture-date {
  font-size: 16px;
  color: #777;
}

.fixture-description {
  font-size: 14px;
  color: #555;
  margin-top: 8px;
}

/* Add the following styles to your CSS file */
.custom-calendar {
  border: none;
  background-color: transparent;
}

.calendar-tile {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.touchable-team-name {
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.touchable-team-name:hover {
  color: #4a90e2;
  text-decoration: underline;
}

.card-content {
  padding: 16px;
}

.card-content p {
  margin: 8px 0;
}

.card-content strong {
  color: #333;
}

.card-content .fixture-status {
  color: red;
}

.add-fixture-btn {
  margin-top: 30px;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 8px;
  background-color: #6558f5;
  color: white;
  text-transform: uppercase;
}

.add-fixture-btn:hover {
  background-color: #4a4ae2;
}

/* Club profile header styling */
.club-photo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.club-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.club-photo, .club-photo-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.club-photo-placeholder {
  background-color: #6558f5;
  color: white;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.club-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #6558f5;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.club-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4a4a4a;
  cursor: pointer;
  margin-top: 20px;
}
/* Apply black color to all calendar days */
.react-calendar__tile {
  color: black !important;
}

/* Target the weekdays */
.react-calendar__month-view__weekdays__weekday {
  color: black !important;
}

/* Ensure the weekend (Saturday and Sunday) text is black */
.react-calendar__tile--weekend {
  color: black !important;
}

/* Apply black text to the current day */
.react-calendar__tile--now {
  color: black !important;
}

/* Optional: Ensure the hovered day text stays black */
.react-calendar__tile:hover {
  color: black !important;
}

.club-profile-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.club-info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.club-info-item {
  flex: 1;
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.club-info-item h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.club-info-item p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 15px;
}

.club-info-item button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.club-info-item button:hover {
  background-color: #0056b3;
}

.fixtures {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fixtures h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.fixtures ul {
  list-style-type: none;
  padding: 0;
}

.fixture-item {
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.fixture-item:hover {
  background-color: #e1e1e1;
}

.fixture-club-name {
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
}

.fixture-date {
  font-size: 1rem;
  color: #555;
}

.fixture-description {
  font-size: 0.9rem;
  color: #777;
}